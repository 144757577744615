import './index.scss'
import React from 'react'
import classNames from 'classnames'

const CopyBufferAlert = ({ classname }) => (
  <span className={classNames('copy-alert', classname)}>
    Анкета скопирована
  </span>
)

export default CopyBufferAlert
