import './index.scss'
import CopyBufferAlert from '../../atoms/CopyBufferAlert'
import CopyLink from '../../atoms/CopyLink'
import MessengerButton from '../../molecules/MessengerButton'
import React, { useCallback, useEffect, useState } from 'react'
import { Event as GAEvent } from '../GA'
import { ReactComponent as SendFailedImg } from '../../../static/img/icons/zayavkaFail.svg'
import { createMessage } from '../../formData/createMessage'
import { messengersUrl } from '../../../static/db.json'

const PopupTextContent = ({ mail }) => (
  <>
    <h3 className="server-error__title">
      Не удалось отправить <br />
      вашу заявку
    </h3>
    {!mail ? (
      <p className="server-error__desc">
        Вы можете скопировать заявку и отправить её напрямую нашему специалисту
        по подбору психолога, пока наша команда инженеров разбирается с
        проблемой
      </p>
    ) : (
      <p className="server-error__desc">
        Вы можете скопировать заявку и отправить её напрямую на почту, пока наша
        команда инженеров разбирается с проблемой
      </p>
    )}
  </>
)

const ModalFooter = {
  MessengersButtons: ({ telegramm }) => (
    <div className="server-error__buttons">
      <MessengerButton.Telegramm href={telegramm} text="Написать" />
      <MessengerButton.Whatsapp href={messengersUrl.whatsapp} text="Написать" />
    </div>
  ),
  SingleButton: ({ link }) => {
    const href = `mailto:${link}`
    return (
      <a
        className="button button_primary server-error__button"
        href={href}
        rel="noreferrer"
        target="_blank"
      >
        Отправить
      </a>
    )
  }
}

const ServerErrorPopup = (props) => {
  const [alertShow, setAlertShow] = useState(false)

  const copyToClipboard = useCallback(() => {
    setAlertShow(true)
    navigator.clipboard.writeText(createMessage(props.allFieldsState))

    GAEvent.BypassPress('Copy')
  }, [])

  useEffect(
    () => alertShow && setTimeout(() => setAlertShow(false), 800),
    [alertShow]
  )

  return (
    <div className="server-error">
      <SendFailedImg className="server-error__img" />
      <PopupTextContent mail={props.mail} />
      <CopyLink classname="server-error__copy-link" click={copyToClipboard} />
      {props.messengers && (
        <ModalFooter.MessengersButtons telegramm={props.telegramm} />
      )}
      {props.mail && <ModalFooter.SingleButton link={props.link} />}
      {alertShow && <CopyBufferAlert classname="server-error__copy-alert" />}
    </div>
  )
}

export default ServerErrorPopup
