import './index.scss'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Event as GAEvent } from '../../components/GA'
import { ReactComponent as TelegramSVG } from '../../../static/img/icons/messengers/telegramm.svg'
import { ReactComponent as Telegramm } from '../../assets/img/icons/telegram.svg'
import { ReactComponent as Whatsapp } from '../../assets/img/icons/whatsapp-new.svg'
import { ReactComponent as WhatsappSVG } from '../../../static/img/icons/messengers/whatsapp.svg'

const MessengerButton = {
  Whatsapp: (props) => (
    <a
      className="button button_whatsapp button_large messenger-button"
      href={props.href}
      onClick={useCallback(() => {
        GAEvent.BypassPress('whatsapp')
        props.onClick()
      }, [])}
      rel="noreferrer"
      target="_blank"
    >
      <span>{props.text}</span> <Whatsapp />
    </a>
  ),
  Telegramm: (props) => (
    <a
      className="button button_telegramm button_large messenger-button"
      href={props.href}
      onClick={useCallback(() => {
        GAEvent.BypassPress('telegram')
        props.onClick()
      }, [])}
      rel="noreferrer"
      target="_blank"
    >
      <span>{props.text}</span> <Telegramm />
    </a>
  )
}

export const NewMessengerButton = styled.a.attrs((props) => ({
  href: props.href
}))`
  position: relative;
  padding: 0;
  border: 2px solid transparent;
  box-sizing: border-box;
  font: inherit;
  color: inherit;
  background-color: transparent;
  padding: 13px 18px;
  background: #eff5fb;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;

  &:hover {
    background: #e9f2fd;
    border-color: #e9f2fd;
    text-decoration: none;
  }

  &:active {
    background: #dfebf9;
    border-color: #dfebf9;
    text-decoration: none;
  }

  &:disabled {
    background: #f3f4f8;
    border-color: #f3f4f8;
    color: #a2a7b1;
    pointer-events: none;

    &:active,
    &:focus,
    &:hover {
      background: #f3f4f8;
      border-color: #f3f4f8;
      color: #a2a7b1;
      pointer-events: none;
    }
  }

  & svg {
    margin-left: 10px;
  }

  &:focus-visible {
    border: 2px solid #000;
  }
`

NewMessengerButton.Telegramm = styled(NewMessengerButton).attrs((props) => ({
  children: (
    <>
      {props.children}
      <TelegramSVG />
    </>
  )
}))``

NewMessengerButton.Whatsapp = styled(NewMessengerButton).attrs((props) => ({
  children: (
    <>
      {props.children}
      <WhatsappSVG />
    </>
  )
}))``

export default MessengerButton
