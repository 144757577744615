import './index.scss'
import React from 'react'
import classNames from 'classnames'
import copy from '../../assets/img/icons/copy.svg'

const CopyLink = ({ click, classname }) => (
  <span className={classNames('copy-link', classname)} onClick={click}>
    <img alt="copy" src={copy} />
    Скопировать
  </span>
)

export default CopyLink
