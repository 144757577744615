import {
  formatBoolToBoolWord,
  formatDisplayDateWithTime,
  formatDisplayNullableValue,
  trimmer
} from '../atoms/formatters'
import { genderTranslationMap } from '../atoms/mappers/wizard/genderMap'
import { getUTM } from '../atoms/initialQueryParams'
import { getYearsWithText } from '../atoms/ageParser'
import { howFeelingTranslationMap } from '../atoms/mappers/wizard/howFeelingMap'
import { isEmpty, keys, pick, sort } from 'rambda'
import { languageTranslationMap } from '../atoms/mappers/wizard/languageMap'
import { string } from 'yup'
import { therapyTypeTranslationMap } from '../atoms/mappers/wizard/therapyTypeMap'

const contactedYoutalkEarlier = ['да', 'нет', 'не помню']

const messengers = ['Telegram', 'WhatsApp']

const client = {
  individual: 'Только я',
  family_partner: 'Я с партнером',
  family_child: 'Я с ребенком'
}

const contactedSpecialistsEarlier = ({ contacted_youtalk: contactedYoutalk }) =>
  contactedYoutalkEarlier[contactedYoutalk]

const specialists = [
  'нет, это первый раз',
  'да, к психологу',
  'да, к психиатру',
  'да, к другому специалисту (невролог, эндокринолог и т.д.)'
]

const contactedSpecialists = ({ has_specialist: hasSpecialist }) => {
  const dataCrmExpepience = hasSpecialist.split(',')
  return dataCrmExpepience
    .map((expperience) => specialists[expperience])
    .join(' ; ')
}

export const ErrorMessageSourceEnum = {
  JobApplication: 'jobApplication',
  B2B: 'b2b',
  Certificate: 'certificate',
  Feedback: 'feedback',
  SelectPsychologist: 'selectPsychologist',
  Registration: 'registration'
}

const queryString = {
  [ErrorMessageSourceEnum.JobApplication]: 'Заявка соискателя',
  [ErrorMessageSourceEnum.B2B]: 'Заявка B2B',
  [ErrorMessageSourceEnum.Certificate]: 'Заявка на подарочный сертификат',
  [ErrorMessageSourceEnum.Feedback]: 'Запрос в службу поддержки',
  [ErrorMessageSourceEnum.SelectPsychologist]: 'Заявка на подбор психолога',
  [ErrorMessageSourceEnum.Registration]: 'Регистрация после визарда/каталога'
}

const sessionType = {
  individual: 'Один на один',
  family: 'Семейный'
}

const fieldsKeys = {
  queryType: (data) => `Тип запроса: ${queryString[data.queryType]}`,
  name: (data) => `Имя: ${data.name}`,
  age: (data) => `Возраст: ${data.age}`,
  timezone: (data) => `Часовой пояс: ${data.timezone}`,
  phone: (data) => `Телефон: +${data.phone}`,
  email: (data) => `Почта: ${data.email}`,
  messenger_coordinator: (data) =>
    `Мессенджер: ${messengers[data.messenger_coordinator]}`,
  sessionFormat: (data) => `Формат сессии: ${data.sessionFormat}`,
  client: (data) => `Кто придёт на сессию: ${client[data.client]}`,
  company: (data) => `Компания: ${data.company}`,
  query: (data) => `Запрос: ${data.query}`,
  content: (data) => `Сообщение: ${data.content}`,
  count_employees: (data) => `Количество сотрудников: ${data.count_employees}`,
  about_help: (data) => `С чем необходима помощь: ${data.about_help}`,
  aboutHelp: (data) => `С чем необходима помощь: ${data.about_help}`,
  about_psychologist: (data) =>
    `Пожелания к психологу: ${
      data.about_psychologist ? data.about_psychologist.trim() : ''
    }`,
  has_specialist: (data) =>
    `Обращались ли ранее к специалистам: ${contactedSpecialists(data)}`,
  contacted_youtalk: (data) =>
    `Обращались ли ранее в YouTalk: ${contactedSpecialistsEarlier(data)}`,
  promo: (data) => `Промокод: ${data.promo}`,
  tariff: (data) => `Тип сертификата: ${sessionType[data.tariff]}`,
  numberOfSessions: (data) => `Количество сессий: ${data.numberOfSessions}`,
  surname: (data) => `Фамилия: ${data.surname}`,
  patronymic: (data) => `Отчество: ${data.patronymic}`,
  birthday: (data) => `День рождения: ${data.birthday}`,
  social_network: (data) =>
    `Ссылка на социальные сети: ${data.social_networks}`,
  residence: (data) => `Страна и город Проживания: ${data.residence}`,
  citizenship: (data) => `Страна гражданства: ${data.citizenship}`,
  education: (data) =>
    `Высшее психологическое образование: ${data.education.trim()}`,
  education_dop: (data) =>
    `Дополнительное образование и повышение квалификации: ${data.education_dop.trim()}`,
  experience: (data) =>
    `Опыт консультирования:  ${getYearsWithText(data.experience)}`,
  experience_correspondence: (data) =>
    `Опыт консультирования в переписке: ${
      data.experience_correspondence === 0 ? 'Да' : 'Нет'
    }`,
  social_networks: (data) =>
    `Профиль в социальной сети: ${data.social_networks}`,
  about_youTalk: (data) => `Откуда узнали о YouTalk: ${data.about_youTalk}`,
  catalogFiltersData: (data) =>
    isEmpty(data)
      ? ''
      : [
          fieldsKeys.name({ name: data.catalogFiltersData.name }),
          fieldsKeys.age({ age: data.catalogFiltersData.age }),
          fieldsKeys.query({
            query: formatDisplayNullableValue(
              trimmer(data.catalogFiltersData.aboutHelp)
            )
          }),
          `Кому нужна помощь: ${
            therapyTypeTranslationMap[data.catalogFiltersData.client]
          }`,
          `Язык сессии: ${
            languageTranslationMap[data.catalogFiltersData.language]
          }`,
          `Формат сесcии: ${formatDisplayNullableValue('Видео/аудио')}`,
          `Как вы себя чувствуете: ${formatDisplayNullableValue(
            howFeelingTranslationMap[data.catalogFiltersData.howFeeling]
          )}`,
          `Пол психолога: ${
            genderTranslationMap[data.catalogFiltersData.gender]
          }`,
          `Возраст психолога: ${data.catalogFiltersData.psychologistAge.join(
            '-'
          )}`,
          `Фрэндли: ${formatBoolToBoolWord(
            data.catalogFiltersData.friendliness
          )}`,
          `Начало сессии: ${formatDisplayDateWithTime(
            data.catalogFiltersData.sessionDateTime
          )}`,
          ...(data.catalogFiltersData.extra ?? [])
        ].join('\n')
}

const sortKeys = {
  [ErrorMessageSourceEnum.JobApplication]: [
    'queryType',
    'name',
    'surname',
    'patronymic',
    'birthday',
    'age',
    'phone',
    'residence',
    'citizenship',
    'education',
    'education_dop',
    'experience',
    'experience_correspondence',
    'social_networks',
    'about_youTalk'
  ],
  [ErrorMessageSourceEnum.B2B]: [
    'queryType',
    'name',
    'phone',
    'email',
    'promo',
    'company',
    'query',
    'count_employees'
  ],
  [ErrorMessageSourceEnum.Certificate]: [
    'queryType',
    'name',
    'phone',
    'email',
    'promo',
    'tariff',
    'numberOfSessions'
  ],
  [ErrorMessageSourceEnum.Feedback]: [
    'queryType',
    'name',
    'phone',
    'email',
    'content'
  ],
  [ErrorMessageSourceEnum.SelectPsychologist]: [
    'queryType',
    'name',
    'age',
    'phone',
    'client',
    'email',
    'promo',
    'aboutHelp',
    'timezone'
  ],
  [ErrorMessageSourceEnum.Registration]: [
    'queryType',
    'phone',
    'name',
    'age',
    'agreement',
    'catalogFiltersData'
  ]
}

const utm = getUTM()

const getSortByKeys = (queryType) => (a, b) =>
  sortKeys[queryType].indexOf(a) - sortKeys[queryType].indexOf(b)

const validate = (queryType) => {
  const schema = string()
    .oneOf(Object.values(ErrorMessageSourceEnum))
    .required()

  return schema.validate(queryType)
}

export const createMessage = (data) => {
  validate(data.queryType)
  const message = []
  const sortedDataKeys = sort(
    getSortByKeys(data.queryType),
    keys(pick(sortKeys[data.queryType], data))
  )

  sortedDataKeys.map(
    (key) => fieldsKeys[key] && message.push(fieldsKeys[key](data))
  )

  Object.entries(utm).forEach(
    ([label, value]) =>
      value && message.push(`${label.toUpperCase()}: ${value}`)
  )

  return message.join('\n')
}
